import { library } from '@fortawesome/fontawesome-svg-core'

/* Solid icons */
import { faExclamationTriangle as fasExclamationTriangle } from '@fortawesome/pro-solid-svg-icons/faExclamationTriangle'
import { faInfoCircle as fasInfoCircle } from '@fortawesome/pro-solid-svg-icons/faInfoCircle'
import { faCaretUp as fasCaretUp } from '@fortawesome/pro-solid-svg-icons/faCaretUp'
import { faCaretDown as fasCaretDown } from '@fortawesome/pro-solid-svg-icons/faCaretDown'

library.add(fasExclamationTriangle, fasInfoCircle, fasCaretUp, fasCaretDown)

/* Regular icons */
// import { faExpandWide as farExpandWide } from '@fortawesome/pro-regular-svg-icons/faExpandWide'

// library.add(
//   farMousePointer,
// )

/* Light icons */
import { faTimes as falTimes } from '@fortawesome/pro-light-svg-icons/faTimes'
import { faArrowToRight as falArrowToRight } from '@fortawesome/pro-light-svg-icons/faArrowToRight'
import { faLongArrowRight as falLongArrowRight } from '@fortawesome/pro-light-svg-icons/faLongArrowRight'
import { faCheckCircle as falCheckCircle } from '@fortawesome/pro-light-svg-icons/faCheckCircle'
import { faPlus as falPlus } from '@fortawesome/pro-light-svg-icons/faPlus'
import { faSpinnerThird as falSpinnerThird } from '@fortawesome/pro-light-svg-icons/faSpinnerThird'
import { faChevronDown as falChevronDown } from '@fortawesome/pro-light-svg-icons/faChevronDown'
import { faExclamationTriangle as falExclamationTriangle } from '@fortawesome/pro-light-svg-icons/faExclamationTriangle'
import { faExternalLink as falExternalLink } from '@fortawesome/pro-light-svg-icons/faExternalLink'
import { faAngleLeft as falAngleLeft } from '@fortawesome/pro-light-svg-icons/faAngleLeft'
import { faAngleRight as falAngleRight } from '@fortawesome/pro-light-svg-icons/faAngleRight'
import { faAngleDoubleRight as falAngleDoubleRight } from '@fortawesome/pro-light-svg-icons/faAngleDoubleRight'
import { faReply as falReply } from '@fortawesome/pro-light-svg-icons/faReply'

library.add(
  falReply,
  falAngleLeft,
  falAngleRight,
  falAngleDoubleRight,
  falExternalLink,
  falArrowToRight,
  falLongArrowRight,
  falCheckCircle,
  falTimes,
  falPlus,
  falSpinnerThird,
  falChevronDown,
  falExclamationTriangle,
)

import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import { i18n } from './i18n'
import { path } from 'ramda'
import '@/styles/main.scss'
import '@/fa'

import VTooltip from 'v-tooltip'
Vue.use(VTooltip, {
  defaultHideOnTargetClick: false,
  defaultOffset: 5,
  defaultDelay: 300,
})

Vue.config.productionTip = false

Vue.prototype.$path = function () {
  let [key, obj, ...args] = arguments

  key = typeof key === 'string' ? key.split('.') : key
  obj = obj || this

  return path.apply(this, [key, obj, ...args])
}

new Vue({
  router,
  store,
  i18n,
  render: h => h(App),
}).$mount('#app')

import Vue from 'vue'
import VueI18n from 'vue-i18n'

let qs = new URLSearchParams(location.search)
const defaultLanguage = qs.get('lang') || localStorage.getItem('lang') || 'nb'

const messages = require(`../i18n/${defaultLanguage}.json`)

// import moment from 'moment'
// if (defaultLanguage !== 'en') require('moment/locale/' + defaultLanguage)
// moment.locale(defaultLanguage)

Vue.use(VueI18n)

export const i18n = new VueI18n({
  locale: defaultLanguage,
  fallbackLocale: defaultLanguage,
  messages: {
    [defaultLanguage]: messages,
  },
})

const loadedLanguages = [defaultLanguage]

function setI18nLanguage(lang) {
  i18n.locale = lang
  document.querySelector('html').setAttribute('lang', lang)
  return lang
}

export function loadLanguageAsync(lang) {
  if (i18n.locale !== lang) {
    if (!loadedLanguages.includes(lang)) {
      return import(/* webpackChunkName: "lang-[request]" */ `../i18n/${lang}.json`).then(msgs => {
        i18n.setLocaleMessage(lang, msgs)
        loadedLanguages.push(lang)
        return setI18nLanguage(lang)
      })
    }
    return Promise.resolve(setI18nLanguage(lang))
  }
  return Promise.resolve(lang)
}

export function mapi18n() {
  let scope = typeof arguments[0] === 'string' ? arguments[0] : undefined
  let value = arguments[scope ? 1 : 0]

  function resolveEntry(entry) {
    let key, args

    switch (entry.constructor) {
      case String:
        key = args = entry
        break
      case Array:
        ;[key, args] = entry
        break
      case Object:
        ;[key, args] = Object.entries(entry)[0]
    }

    switch (args.constructor) {
      case Function:
        return [key, args.call(this)]
      case String:
        return [key, i18n.tc(args)]
      case Array:
        return [key, i18n.tc(...args)]
      default:
        return [key, i18n.tc(key, args)]
    }
  }

  function build() {
    let base = value instanceof Array ? value : Object.entries(value)
    return Object.fromEntries(base.map(resolveEntry.bind(this)))
  }

  if (!scope) return build()
  return {
    [scope]: build,
  }
}
